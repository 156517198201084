<script>
import InvalidScanDialog from "../InvalidScanDialog";
import { mountsBarcodeHandlerWhenActive } from "@/mixins/barcode";
import { BUY_VIEW } from "../graphql";
import { mapMutations } from "vuex";

export default {
  name: "BuyScanV1",
  components: { InvalidScanDialog },
  mixins: [mountsBarcodeHandlerWhenActive],
  props: {
    storeId: { type: String, default: "" },
    currentTab: { type: String, default: "" },
  },
  data() {
    return {
      buysLoading: false,
      searchNumber: undefined,
      invalidScanDialogOpened: false,
    };
  },
  computed: {
    queryVariables() {
      return {
        storeId: this.storeId,
      };
    },
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),
    handleBarcode({ detail: { data } }) {
      let searchNumber = data ? data.toString() : "";

      if (this.storeId && searchNumber) {
        if (searchNumber.length >= 10) {
          // It means it is a licence plate and we should
          // 1. if there is a buy/checkin opened -> let that form handle it (enters the licence data)
          // 2. if the start new buy/checkin form is opened -> let that form handle it (sets the data and finds the user)
          // 3. if there is no buy/checkin opened -> open a start new buy/checkin form and let that form handle the data for that licence plate
          if (this.$route.name === "buys_edit_checkinrequset") {
            // Nothing to do handled by the opened form
            return;
          } else if (this.$route.name === "buys_new") {
            // Nothing to do handled by the opened form
            return;
          } else {
            const routeToNavigateTo = {
              name: "buys_new",
              preserveQuery: true,
              query: {
                initialLicenseData: searchNumber,
              },
            };
            if (this.$route.name === "buys_home") {
              this.$router.push(routeToNavigateTo);
            } else {
              this.$router.replace(routeToNavigateTo);
            }
          }
          return;
        }

        searchNumber = searchNumber.replace(/\r/g, "");

        this.$apollo
          .query({
            query: BUY_VIEW,
            loadingKey: "buysLoading",
            variables: {
              ...this.queryVariables,
            },
          })
          .then((response) => {
            if (!response || !response.data || !response.data.buys) {
              this.invalidScanDialogOpened = true;
            } else {
              // It is transaction id
              let foundBuy = response.data.buys.find(
                (buy) => buy.transId === searchNumber
              );

              if (!foundBuy) {
                this.invalidScanDialogOpened = true;
              } else {
                const routeToNavigateTo = {
                  name: "buys_edit",
                  params: {
                    buyId: foundBuy.id,
                  },
                  query: {
                    t: foundBuy.status,
                  },
                  preserveQuery: true,
                };

                if (this.$route.name === "buys_home") {
                  this.$router.push(routeToNavigateTo);
                } else {
                  this.$router.replace(routeToNavigateTo);
                }
              }
            }
          })
          .catch((error) => {
            this.showSnackbar({
              text: `An error occurred while retrieving buy. ${error}`,
            });
          });
      }
    },
  },
};
</script>

<template>
  <InvalidScanDialog v-model="invalidScanDialogOpened" />
</template>
