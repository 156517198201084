import BuyScan from "./BuyScan.vue";
import BuyScanV1 from "./BuyScanV1.vue";
import { defineFeatureComponent } from "@/core-ui";

export default defineFeatureComponent({
  name: "BuyScan",
  features: [
    {
      feature: "checkinFlowVersion",
      variant: "V1",
      component: BuyScanV1,
    },
    {
      feature: "checkinFlowVersion",
      variant: "V2",
      component: BuyScan,
    },
  ],
});
