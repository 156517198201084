// Mounts and dismounts `handleScan` on the `onbarcodeinput`
// event for the following lifecycle hooks:
//   - created
//   - destroyed
//   - activated
//   - deactivated
export const mountsBarcodeHandlerWhenActive = {
  created() {
    this.mountBarcodeHandler();
  },
  destroyed() {
    this.dismountBarcodeHandler();
  },
  activated() {
    this.mountBarcodeHandler();
  },
  deactivated() {
    this.dismountBarcodeHandler();
  },
  methods: {
    mountBarcodeHandler() {
      // DEPRECATED
      // { detail: 'BARCODEDATA' }
      window.addEventListener("onbarcodeinput", this.handleScan);

      // { detail: { data: 'BARCODEDATA' }}
      // future plan to add more keys when possible:
      // - type ['CODE39', 'CODE128', 'PDF417', 'QRCODE', etc]
      window.addEventListener("onbarcode", this.handleBarcode);
    },
    dismountBarcodeHandler() {
      window.removeEventListener("onbarcodeinput", this.handleScan);
      window.removeEventListener("onbarcode", this.handleBarcode);
    },
  },
};
