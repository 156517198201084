<script>
import InvalidScanDialog from "../InvalidScanDialog";
import { mountsBarcodeHandlerWhenActive } from "@/mixins/barcode";
import { BUY_VIEW } from "../graphql";
import { mapMutations } from "vuex";

export default {
  name: "BuyScan",
  components: { InvalidScanDialog },
  mixins: [mountsBarcodeHandlerWhenActive],
  props: {
    storeId: { type: String, default: "" },
    currentTab: { type: String, default: "" },
  },
  data() {
    return {
      buysLoading: false,
      searchNumber: undefined,
      invalidScanDialogOpened: false,
    };
  },
  computed: {
    queryVariables() {
      return {
        storeId: this.storeId,
      };
    },
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),
    handleBarcode({ detail: { data } }) {
      let searchNumber = data ? data.toString() : "";
      searchNumber = searchNumber.replace(/\r/g, "");

      const transIdRegex = new RegExp(/^\d{4,9}$/);
      if (this.storeId && searchNumber && transIdRegex.test(searchNumber)) {
        // searchNumber.length < 10
        // - It means it could be a valid transaction id

        // TODO - add tech debt to do this smarter, via query that does not return all buys
        this.$apollo
          .query({
            query: BUY_VIEW,
            loadingKey: "buysLoading",
            variables: {
              ...this.queryVariables,
            },
          })
          .then((response) => {
            if (!response || !response.data || !response.data.buys) {
              this.invalidScanDialogOpened = true;
            } else {
              // It is transaction id
              let foundBuy = response.data.buys.find(
                (buy) => buy.transId === searchNumber
              );

              if (!foundBuy) {
                this.invalidScanDialogOpened = true;
              } else {
                const routeToNavigateTo = {
                  name: "buys_edit",
                  params: {
                    buyId: foundBuy.id,
                  },
                  query: {
                    t: foundBuy.status,
                  },
                  preserveQuery: true,
                };

                if (this.$route.name === "buys_home") {
                  this.$router.push(routeToNavigateTo);
                } else {
                  this.$router.replace(routeToNavigateTo);
                }
              }
            }
          })
          .catch((error) => {
            this.showSnackbar({
              text: `An error occurred while retrieving buy. ${error}`,
            });
          });
      }
    },
  },
};
</script>

<template>
  <InvalidScanDialog v-model="invalidScanDialogOpened" />
</template>
