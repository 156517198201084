<script>
export default {
  inheritAttrs: false,
  methods: {
    confirm() {
      this.$emit("input", false);
    },
  },
};
</script>

<template>
  <v-dialog :max-width="400" v-bind="$attrs" persistent v-on="$listeners">
    <v-card>
      <v-container>
        <v-card-text>
          <h1 class="text-h5 mb-3 accent--text">Buy not found</h1>
          <section>Buy with scanned transaction id was not found.</section>
        </v-card-text>
      </v-container>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" @click="confirm" v-text="`Ok`" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
